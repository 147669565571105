<template>
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" rx="40" fill="#E1F3FF"/>
    <rect x="8" y="8" width="64" height="64" rx="32" fill="#F7FCFF"/>
    <path d="M53.3346 26.666L38.668 41.3327" stroke="#475661" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M53.3346 26.666L44.0013 53.3327L38.668 41.3327L26.668 35.9993L53.3346 26.666Z" stroke="#475661" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "SendSuccessIcon"
}
</script>

<style scoped>

</style>