<template>
  <div>
    <OnboardingHeader/>

    <div class="tw-mt-">
      <div id="background"
           class="md:tw-h-72 tw-w-full"
           style="background-size: cover !important" v-bind:style="{ backgroundImage: 'url(' + require('@/assets/images/test-background.png') + ')'}">
        <div @click="$router.go(-1)" class="tw-flex tw-cursor-pointer md:tw-m-auto tw-ml-5 tw-pb-8 tw-pt-24   md:tw-w-10/12">
          <vue-feather type="arrow-left"></vue-feather>
          <h4 class="tw-hidden md:tw-block">Back</h4>
        </div>
        <div class="instruction_details tw-p-6 tw-m-auto md:tw-w-8/12">
          <div class="tw-flex tw-justify-between">
            <div>
              <h4 v-if="this.$route.query.is_external_test">
                Skill Assessment
              </h4>
              <h4 v-else>
                {{$route.query.jobTitle || $route.query.programTitle}} {{$route.query.category}}
              </h4>
              <div v-if="$route.query.time&&$route.query.time!=='0'" class="tw-flex tw-items-center">
                <vue-feather style="color:#9CABB5; width:18px" type="clock"></vue-feather>
                <span class="tw-ml-2" >{{$route.query.time}}mins</span>
              </div>
            </div>
            <div class="tw-hidden md:tw-block tw-w-40" v-if="!this.$route.query.is_external_test">
              <BaseButton  @click="goToAssessmentQuestion" :block="true" button-text="Begin Test"/>
            </div>

          </div>
        </div>

      </div>
      <div v-if="this.$route.query.is_external_test" class=" tw-pt-16 tw-mx-6 md:tw-m-auto md:tw-w-8/12">
        <div class="md:tw-w-full md:tw-ml-8">
          <p>NEXT STEP</p>
          <p>A link to this test will be forwarded to your registered email address.</p>
          <p class="tw-pt-4">The link will contain instructions to take the skill assessment on a third party platform. As soon as you <br/> have requested the invite,
            please ensure to look through your inbox and spam folders to find the email
            <br/> and complete the assessment within the time allowed</p>
          <p class="tw-pt-8">We wish you success!</p>
          <div class="tw-flex align-center tw-cursor-pointer">
            <v-checkbox
                v-model="checkbox"
                class=" tw-pb-0"
                color="#8F47F4"
                type="checkbox">
            </v-checkbox>
            <span class="tw-ml-2 terms" style="color:#475661">I have read and understood the instructions above </span>
          </div>
          <div class="tw-w-40">
            <BaseButton @click="inviteCandidate" :block="true" button-text="Invite me" :disabled="!checkbox || is_invited.is_invited_external_test"></BaseButton>
          </div>
        </div>
      </div>
      <div class=" tw-pt-16 tw-mx-6 md:tw-m-auto md:tw-w-8/12" v-else>
        <div class="md:tw-w-80 md:tw-ml-8">
          <h5 class="instruction">Instruction</h5>
<!--          <h2>|Predefined Instructions| </h2>-->
          <ul v-for="(instruction,index) in instructions" :key="index">
            <li>{{ instruction }}</li>
          </ul>
          <div>
<!--            <h2>|Question Bank Instructions|</h2>-->
            <h2 class="tw-mt-4 font-weight-bold">Attempt All Questions.</h2>
          </div>
          <div class="tw-mt-16  tw-mb-6 md:tw-hidden">
            <BaseButton @click="goToAssessmentQuestion" :block="true" button-text="Begin Test"></BaseButton>
          </div>
        </div>
      </div>
    </div>

    <skill-assessment-modal :open-dialog="open"  @close="open=false" :company-name="this.$route.query.company_name" :job-title="this.$route.query.job_title"/>
  </div>
</template>

<script>
import OnboardingHeader from "@/components/reusables/OnboardingHeader";
import BaseButton from "@/components/reusables/BaseButton";
import SkillAssessmentModal from "@/components/dashboard/jobAssessment/SkillAssessmentModal";
import {mapGetters} from "vuex";

export default {
  name: "AssessmentInstruction",
  components: {SkillAssessmentModal, BaseButton, OnboardingHeader},
  data() {
    return {
      checkbox: false,
      open: false,
      skillInstructions: {
        first_instruction: 'Make sure you have a stable internet connection before you start the test.',
        second_instruction: 'Once test has begun, you cannot go back as you would be forced to submit.',
      },
      personalityInstructions: {
        first_instruction: 'Make sure you have a stable internet connection before you start the test.',
        second_instruction: 'Once test has begun, you cannot go back as you would be forced to submit.',
      },
      psychometricInstructions: {
        first_instruction: 'Make sure you have a stable internet connection before you start the test.',
        second_instruction: 'Once test has begun, you cannot go back as you would be forced to submit.',
      }
    }
  },
  created() {
    if(this.$route.query.is_external_test){
      this.checkInvite()
    }
  },
  computed:{
    ...mapGetters(['is_invited']),
    instructions(){
      let category = this.$route.query.category
      if(category === 'Skill Test'){
        return this.skillInstructions
      }
      else if(category ==='Personality Test'){
        return this.personalityInstructions
      }
      else{
        return this.psychometricInstructions
      }
    }

  },
  methods: {
    goToAssessmentQuestion() {
      // let category = this.$route.query.category
      // if(category === 'Skill Test'){
      this.$router.push({name: 'AssessmentQuestionView', query: this.$route.query})

      // }
      // else{
      //   this.$router.push({name: 'AssessmentQuestionPersonalityType'})
      // }
    },
    inviteCandidate() {
      let data = {}
      data.job_id = this.$route.query.jobId
      data.external_test_id = this.$route.query.test_id
      try {
        this.$store.dispatch('inviteCandidateForExternalTest', data)
        this.open = true
      } catch (e) {
        console.log(e)
      }
    },
    checkInvite() {
      let data = {}
      data.job_id = this.$route.query.jobId
      data.external_test_id = this.$route.query.test_id
      try {
        this.$store.dispatch('checkInvitedCandidate', data)
      } catch (e) {
        console.log(e)
      }
    }
  }
}

</script>

<style scoped>
.instruction_details h3 {
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #1E323F;
}
.instruction{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #475661;
  margin-bottom:20px;
}
.instruction_details h4 {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 23px;
  color: #008EEF;

}

.instruction_details span {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.instruction_details h2{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 10px;
  color: #1E323F;
}
li {
  list-style: disc;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 10px;
  color: #1E323F;
}
p{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #1E323F;
}
</style>
