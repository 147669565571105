<template>
  <div>
    <div>
      <v-dialog
          v-model="openDialog"
          max-width="430"
          transition="dialog-top-transition"

      >
        <v-card class="tw-p-3 md:tw-p-5 card">
          <div class="inner-content">
            <div class="mb-8">
              <send-success-icon/>
            </div>
            <h3 class="inner-title tw-w-full">Assessment link has been sent to your registered
              email address. Good luck.</h3>
            <base-button class=" tw-mt-6 tw-cursor-pointer" button-text="Got it" @click="goto" width="'160'"/>
          </div>
        </v-card>
      </v-dialog>
    </div>

  </div>
</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";
import SendSuccessIcon from "@/components/icons/SendSuccessIcon";

export default {
  name: "SkillAssessmentModal",
  components: {SendSuccessIcon, BaseButton,},
  data: ()=> {
    return {
      title: "Your application has been sent \n" +
          " to ",
    }
  },
  props:{
    openDialog: [Boolean],
    companyName: [String],
    jobTitle: [String]
  },
  methods:{
    close() {
      this.$emit('close')
    },
    goto(){
      this.$router.push({path:`/dashboard/jobs/applied/${this.$route.query.jobId}`,query:{company_name:`${this.companyName}`, job_title:`${this.jobTitle}`}})
    }
  }
}
</script>

<style scoped>
.card{
  min-height: 300px;
  background: #FFFFFF;
  box-shadow: 0px 24px 32px rgba(0, 61, 103, 0.16), 0px 4px 4px rgba(0, 61, 103, 0.1);
  border-radius: 12px;
}
.inner-title{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #475661;
}
.subtext{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #008EEF;
}
.inner-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>